import React from 'react'
import Helmet from 'react-helmet'
 
import Layout from '../components/layout'
import PostTiles from '../components/PostTiles'

import pic11 from '../assets/images/pic11.jpg'
import Feed from "react-instagram-authless-feed"

const Galeria = ({props}) => (
    <Layout>
        <Helmet>
            <title>Articulos</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="galeria" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Galeria</h1>
                    </header>
 
                    <Feed userName="proyectorevelarteok" className="Feed" classNameLoading="Loading"/>
    </div>
            </section>
        </div>

    </Layout>
)


 

export default Galeria